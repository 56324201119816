<!--
  PACKAGE_NAME : src/pages/ai/forecast/forecast-card.vue
  FILE_NAME : forecast-card
  AUTHOR : tk1ee
  DATE : 2024-10-21
  DESCRIPTION : AI 인력 예측 카드 컴포넌트
-->
<template>
  <div class="card">
    <h3 class="title">{{ skillId }}</h3>
    <div class="progress-container">
      <div class="progress-row">
        <span class="label">현재</span>
        <div class="progress-bar-wrapper">
          <div class="progress-bar">
            <div
                class="progress-fill current"
                :style="{ width: `${(currentStaff / maxValue) * 100}%` }"
            ></div>
          </div>
          <span class="count">{{ currentStaff }}명</span>
        </div>
      </div>
      <div class="progress-row">
        <span class="label">예측</span>
        <div class="progress-bar-wrapper">
          <div class="progress-bar">
            <div
                class="progress-fill"
                :style="{
                width: `${(forecastedStaff / maxValue) * 100}%`,
                backgroundColor: forecastedColor
              }"
            ></div>
          </div>
          <span class="count">{{ forecastedStaff }}명</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skillId: {
      type: String,
      required: true
    },
    currentStaff: {
      type: Number,
      required: true
    },
    forecastedStaff: {
      type: Number,
      required: true
    }
  },
  computed: {
    maxValue() {
      return Math.max(this.currentStaff, this.forecastedStaff);
    },
    forecastedColor() {
      return this.currentStaff < this.forecastedStaff ? '#FF3B30' : '#4B6BBF';
    }
  }
}
</script>

<style scoped>
.card {
  padding: 1.5rem 2rem;
  width: 19.7%;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: white;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.progress-row {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.progress-bar-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
  width: 2.5rem;
}

.progress-bar {
  flex: 1;
  height: 0.75rem;
  background-color: transparent;
  border-radius: 9999px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  border-radius: 9999px;
  transition: width 0.3s ease;
}

.progress-fill.current {
  background-color: #9ca3af;
}

.count {
  font-size: 1rem;
  font-weight: 500;
  min-width: 3.5rem;
}
</style>
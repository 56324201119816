<!--
  PACKAGE_NAME : src/pages/ai/forecast/list.vue
  FILE_NAME : list
  AUTHOR : tk1ee
  DATE : 2024-10-18
  DESCRIPTION : AI 인력 예측
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <div class="ui-datepicker-item pad_top10">
        <DxDateBox
            :width="config.dateBox.width"
            :type="config.dateBox.type"
            :display-format="config.dateBox.displayFormat"
            :styling-mode="config.stylingMode"
            :value="currentDate"
            :disabled="true"
            class="dark-text"
        >
        </DxDateBox>
      </div>
      <div class="page-bin lin1 pad_top10"></div>
      <div class="flex justify-between pad_top10">
        <ForecastCard
            v-for="data in top5StaffDifference"
            :key="data.skill_id"
            :skill-id="data.skill_id"
            :current-staff="data.current_staff"
            :forecasted-staff="Math.ceil(data.full_time_equivalent / (3600 * 8))"
        />
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-prepared="onRowPrepared" />
    </div>
  </div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
import ForecastCard from "@/pages/ai/forecast/forecast-card.vue";
import {DxButton} from "devextreme-vue/button";

export default {
  components: {
    ForecastCard,
    EspDxDataGrid,
    DxDateBox
  },
  props: {},
  data() {
    return {
      gridData: [],
      currentDate: new Date(),
      currentStaffData: [
        {
          "skill_id": "전체",
          "current_staff": 169
        },
        {
          "skill_id": "대출/신규",
          "current_staff": 10
        },
        {
          "skill_id": "대출/연체",
          "current_staff": 0
        },
        {
          "skill_id": "대출/상담",
          "current_staff": 70
        },
        {
          "skill_id": "대출/외국어",
          "current_staff": 5
        },
        {
          "skill_id": "대출/VIP",
          "current_staff": 2
        },
        {
          "skill_id": "대출/심사",
          "current_staff": 65
        },
        {
          "skill_id": "대출/기업",
          "current_staff": 8
        },
        {
          "skill_id": "대출/담보",
          "current_staff": 2
        },
        {
          "skill_id": "대출/온라인",
          "current_staff": 5
        },
        {
          "skill_id": "대출/중금리",
          "current_staff": 2
        }
      ],
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        dateBox: {
          width: 120,
          type: 'date',
          displayFormat: 'yyyy-MM-dd',
        },
      },
      dataGrid: {
        callApi: 'CALL_FORECAST_API',
        keyExpr: 'skill_id', // 그리드 키값
        refName: 'forecastGrid',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        // dataSourceDefaultSortColumn: '-staff_difference', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
        dataSource: [],
        // width:'200',                                     // 주석처리시 100%
        // height:'500',                                    // 주석처리시 100%
        apiActionNm: {},
        customEvent: {
          rowPrepared: true,
        },
        showActionButtons: {},
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        // scrolling: {                                     // 미사용시 주석처리
        //     mode: 'virtual'                              //스크롤 모드 : ['infinite', 'standard', 'virtual']
        // },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '스킬 그룹',
            dataField: 'skill_id',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'asc', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
            sortIndex: 0,
            calculateSortValue: (rowData) => {
              // '전체'는 가장 작은 값을 반환하여 최상단에 위치
              return rowData.skill_id === '전체' ? -1 : 1;
            },
          },
          {
            caption: '실제 콜',
            dataField: 'actual_offer',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
            headerCellTemplate: container => {
              const div = document.createElement('div');
              div.innerHTML = `<span style="font-weight: bold;">실제 콜</span>
                               <span
                                  title="실제 콜은 지난주 같은 요일의 값입니다."
                                  class="mdi mdi-help-circle"
                                >
                                </span>
                                `;
              container.append(div);
            },
          },
          {
            caption: '예측 콜',
            dataField: 'forecasted_offer',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
          },
          {
            caption: '예측 인력 (8시간 근무 기준)',
            dataField: 'full_time_equivalent',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
            calculateCellValue: function(rowData) {
              return Math.ceil(rowData.full_time_equivalent / (3600 * 8));
            }
          },
          {
            caption: '현재 인력',
            dataField: 'current_staff',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
          },
          {
            caption: '인력 편차',
            dataField: 'staff_difference',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortIndex: '1',
            sortOrder: 'desc', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowSorting: false,
            fixed: true, // 컬럼 fix 시 사용
            calculateSortValue: (rowData) => {
              // '전체' 행은 정렬에서 제외
              return rowData.skill_id === '전체' ? null : Math.abs(rowData.staff_difference);
            },
            cellTemplate: function(container, options) {
              const value = options.value;
              const displayValue = value > 0 ? `+${value}` : value;
              const color = value > 0 ? '#d2504b' : value < 0 ? '#4b6bbf' : 'inherit';

              const div = document.createElement('div');
              div.style.color = color;
              div.textContent = displayValue;
              container.appendChild(div);
            }
          },
          {
            caption: '스킬 설정',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true, // 컬럼 fix 시 사용
            cellTemplate: (container) => {
              let button = new DxButton({
                propsData: {
                  text: '스킬 설정 가기',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 105,
                  height: 20,
                  onClick: () => {
                    this.$router.push('/cc/skl/assign/list');
                  },
                },
              });
              button.$mount();
              container.append(button.$el);
            },
          },
        ],
      },
    };
  },

  computed: {
    top5StaffDifference() {
      return this.gridData
          .filter(item => item.skill_id !== '전체')
          .sort((a, b) => Math.abs(b.staff_difference) - Math.abs(a.staff_difference))
          .slice(0, 5);
    }
  },

  methods: {
    /** @description: 그리드 행 관련 준비 이벤트 */
    onRowPrepared(e) {
      if (e.data && e.data.skill_id === '전체') {
        e.rowElement.style.backgroundColor = '#FFF8ED';
      }
    },

    /** @description: 데이터 조회 메서드 */
    async selectDataList() {
      let vm = this;
      this.dataGrid.dataSource = new CustomStore({
        key: 'skill_id',
        async load() {
          const params = {};

          params.frequency = 'daily';
          params.start_date = '2024-08-15';
          params.end_date = '2024-08-15';
          params.skill_ids = 'all';

          const payload = {
            actionname: 'FORECAST_LIST',
            data: params,
          };

          const res = await vm.CALL_FORECAST_API(payload);

          console.log('@@@@@@@@@', res);

          let rtnData = {
            data: [],
            totalCount: 0,
          };
          if (res.status === 200) {
            const skillIdMapping = {
              '국내선/한국어': '대출/신규',
              '국제선 야간/항공권': '대출/연체',
              '국제선 주간/한국어': '대출/상담',
              '국제선 야간/한국어': '대출/외국어',
              '국제선 야간/우수회원': '대출/VIP',
              '국제선 주간/항공권': '대출/심사',
              '국제선 주간/우수회원': '대출/기업',
              '국내선/우수회원': '대출/담보',
              '국제선 주간/IT 서비스': '대출/온라인',
              '국내선/영어': '대출/중금리'
            };

            // API 응답 데이터의 skill_id를 새로운 값으로 매핑
            const mappedApiData = res.data.map(item => ({
              ...item,
              skill_id: skillIdMapping[item.skill_id] || item.skill_id
            }));

            // 현재 인력 데이터 추가
            const processedData = mappedApiData.map(item => {
              const staffData = vm.currentStaffData.find(
                  staff => staff.skill_id === item.skill_id
              );
              const currentStaff = staffData ? staffData.current_staff : 0;
              const forecastedStaff = Math.ceil(item.full_time_equivalent / (3600 * 8));
              return {
                ...item,
                current_staff: currentStaff,
                staff_difference: currentStaff - forecastedStaff
              };
            });

            const totalData = vm.currentStaffData.find(
                staff => staff.skill_id === '전체'
            );

            const totalRow = {
              skill_id: '전체',
              actual_offer: mappedApiData.reduce((sum, item) => sum + (item.actual_offer || 0), 0),
              forecasted_offer: mappedApiData.reduce((sum, item) => sum + (item.forecasted_offer || 0), 0),
              full_time_equivalent: mappedApiData.reduce((sum, item) => sum + (item.full_time_equivalent || 0), 0),
              current_staff: totalData ? totalData.current_staff : 0,
              staff_difference: totalData ?
                  totalData.current_staff - Math.ceil(mappedApiData.reduce((sum, item) => sum + (item.full_time_equivalent || 0), 0) / (3600 * 8)) : 0
            };

            const finalData = [totalRow, ...processedData];
            vm.gridData = finalData;

            rtnData = {
              data: finalData,
              totalCount: mappedApiData.length,
            };

            vm.$refs[vm.dataGrid.refName].totalCount = rtnData.totalCount;
          }
          return rtnData;
        },
      });
    },
  },

  created() {},

  mounted() {
    this.selectDataList();
  },
};
</script>
<style scoped>
:deep(.dark-text) .dx-texteditor-input {
  font-weight: 900 !important;
}
</style>